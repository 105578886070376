<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t(`account.validate_or_change.${typeValidation}.verify`)"
          />
          <q-space />
          <q-btn flat round dense icon="close" class="q-mr-sm" v-close-popup />
        </q-card-section>
        <q-separator class="q-mr-md q-ml-md" />
        <div>
          <q-form @submit="submit()" greedy>
            <q-card-section class="q-pt-none q-mt-md">
              <div
                class="bg-grey-3 q-pa-md text-justify default-rounded"
                v-html="
                  $t(
                    `account.validate_or_change.${typeValidation}.label_step_2`,
                    {
                      value: email,
                    }
                  )
                "
              />
              <get-code-component @getCode="setCode($event)" />
            </q-card-section>
            <q-card-section class="q-pt-none q-mt-md q-mb-md">
              <div
                class="text-justify"
                :class="{
                  'text-grey-8': inTime > 0,
                  'text-primary': inTime == 0,
                }"
              >
                <span
                  @click="resend"
                  :style="`cursor: ${
                    inTime > 0 ? 'wait' : 'pointer'
                  }; font-size: 0.9em;`"
                  >Não recebeu o código? Clique aqui para reenviar.</span
                >
                {{ inTime ? `(${inTime}s)` : "" }}
              </div>
            </q-card-section>
            <q-card-section class="bg-grey-3 q-ma-md default-rounded">
              <div class="q-pa-xs text-justify" style="font-size: 0.95em">
                Está com dificuldades? Envie um email para
                <b>suporte@flip.net.br</b> e ajudaremos você.
              </div>
            </q-card-section>
            <q-card-actions align="center">
              <q-btn
                unelevated
                color="dark"
                type="submit"
                :disabled="code.length < 6"
                :loading="in_request_validate"
                :label="$t('global.verify')"
                class="text-weight-regular"
              />
            </q-card-actions>
          </q-form>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useActions, useGetters } = createNamespacedHelpers("account");
import GetCodeComponent from "./GetCodeComponent.vue";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

export default {
  name: "ValidateEmailExt",

  components: {
    GetCodeComponent,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
    typeValidation: {
      type: String,
      default: "email",
    },
  },

  emits: ["verify", "resend"],

  setup(props, { emit }) {
    const { t } = useI18n();

    const code = ref("");
    const inTime = ref(0);
    const in_request_validate = ref(false);
    const errorMessage = ref(
      `O campo ${props.typeValidation} já está sendo utilizado`
    );

    const { fetchValidateChallengeExt } = useActions([
        "fetchValidateChallengeExt",
      ]),
      { challenge_code, getExtChallengeSecret } = useGetters({
        challenge_code: "getChallengeCode",
        getExtChallengeSecret: "getExtChallengeSecret",
      });

    const submit = () => {
      in_request_validate.value = true;
      fetchValidateChallengeExt({
        type: props.typeValidation == "email" ? "email" : "sms",
        code: code.value,
        hash: props.hash,
        secret: getExtChallengeSecret.value,
      })
        .then((res) => {
          if (res.validated == true) {
            notifySuccess(
              t(`account.validate_or_change.${props.typeValidation}.verified`)
            );
            emit("verify");
          } else notifyError(t("account.validate_or_change.invalid_code"));
        })
        .catch(() => notifyError(t("account.validate_or_change.invalid_code")))
        .finally(() => (in_request_validate.value = false));
    };

    const resend = () => {
      if (inTime.value > 0) return;
      inTime.value = 30;
      let intervalId = setInterval(() => {
        if (inTime.value > 0) {
          inTime.value--;
        } else clearInterval(intervalId);
      }, 1000);

      emit("resend");
    };

    return {
      code,
      inTime,
      errorMessage,
      challenge_code,
      in_request_validate,
      submit,
      resend,
      setCode: (e) => (code.value = e),
      clearData: () => {
        code.value = "";
      },
    };
  },
};
</script>

<style scoped>
.form {
  width: 460px;
}

@media screen and (max-width: 700px) {
  .form {
    width: 100%;
  }
}
</style>
